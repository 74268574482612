<template>

    <div>
        <b-card no-body>
            <b-card-header>
                <b-card-title>Готовые решения</b-card-title>
                <div class="d-flex">
                    <b-button pill variant="primary" class="ml-1" :to="{ name: 'admin-contracts-offers-create' }">Создать предложение</b-button>
                </div>
            </b-card-header>
            
            <b-skeleton-table v-if='is_loading' animation="fade" :columns='9' :rows='5'>
            
            </b-skeleton-table>
            <b-table-simple v-else >
                <b-thead>
                    <b-tr class="font-small-2 text-nowrap text-muted">
                        <b-td>#</b-td>
                        <b-td>Название</b-td>
                        <b-td>Тип</b-td>
                        <b-td>Мощность</b-td>
                        <b-td>Стоимость</b-td>
                        <b-td>Период</b-td>
                        <b-td>Окончание</b-td>
                        <b-td></b-td>
                    </b-tr>
                </b-thead>
                <b-tbody>
                    <b-tr v-for="(item, index) in items">
                        <b-td class="text-black text-nowrap">{{ item.id }}</b-td>
                        <b-td class="text-black text-nowrap font-weight-bolder">
                            <template v-if="item.miner">
                                {{ item.miner.name }}
                            </template>
                            <template v-else >
                                {{ item.title }}
                            </template>
                        </b-td>
                        <b-td class="text-black text-nowrap">
                            <template v-if="item.type === 1">
                                <feather-icon icon="CloudIcon" size="13" class="text-primary" />
                                <span class="font-small-3" style="padding-left: 5px;">Облако</span>
                            </template>
                            <template v-if="item.type === 2">
                                <feather-icon icon="CpuIcon" size="13" class="text-danger" />
                                <span class="font-small-3" style="padding-left: 5px;">Майнер</span>
                            </template>
                        </b-td>
                        <b-td class="text-black text-nowrap">
                            <template v-if="item.ths">
                                {{ item.ths.formatMoney(2," "," ") }}
                                 <span class="text-muted">TH/s</span>
                            </template>
                            <span v-else-if="item.miner">
                                {{ item.miner.hashrate }}
                                <span class="text-muted">TH/s</span>
                            </span>
                        </b-td>
                        <b-td class="text-black text-nowrap">
                            {{ item.price_amount.formatMoney(0," "," ") }}
                            <span class="text-muted">$</span>
                        </b-td>
                        <b-td class="text-black text-nowrap">
                            <template v-if="item.period">
                                {{ item.period }} мес.
                            </template>
                            <span v-else>–</span>
                        </b-td>
                        <b-td class="text-black text-nowrap">{{ Utils.timeAgo(item.until) }}</b-td>
                        <b-td class="text-center">
                            <b-dropdown
                                size="sm"
                                right
                                no-caret   
                                variant="transparent"
                            >
                                <template #button-content>
                                    <feather-icon icon="MoreVerticalIcon" class="text-muted" size="22" />
                                </template>
                                <b-dropdown-item :to="{ name: 'admin-contracts-offers-edit', params: { offer: item } }">
                                    <feather-icon icon="EditIcon" class="text-primary" size="13" />
                                    <span class="text-primary"> Редактировать</span>
                                </b-dropdown-item>
                                <b-dropdown-item @click="preferDeleteAction(item, index )">
                                   <feather-icon icon="TrashIcon" class="text-danger" size="13" />
                                   <span class="text-danger"> Удалить</span>
                                </b-dropdown-item>
                            </b-dropdown>
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
        </b-card>

        <b-card no-body v-if="specials.length > 0">
            <b-card-header>
                <b-card-title>Веб спецпредложения</b-card-title>
            </b-card-header>
            
            <b-skeleton-table v-if='is_loading' animation="fade" :columns='9' :rows='5'>
            
            </b-skeleton-table>
            <b-table-simple v-else >
                <b-thead>
                    <b-tr class="font-small-2 text-nowrap text-muted">
                        <b-td>#</b-td>
                        <b-td>Название</b-td>
                        <b-td>Тип</b-td>
                        <b-td>Период</b-td>
                        <b-td>Время жизни</b-td>
                        <b-td>Ссылка</b-td>
                        <b-td></b-td>
                    </b-tr>
                </b-thead>
                <b-tbody>
                    <b-tr v-for="(item, index) in specials">
                        <b-td class="text-black text-nowrap">{{ item.id }}</b-td>
                        <b-td class="text-black text-nowrap font-weight-bolder">
                            <template v-if="item.miner">
                                {{ item.miner.name }}
                            </template>
                            <template v-else >
                                {{ item.title }}
                            </template>
                        </b-td>
                        <b-td class="text-black text-nowrap">
                            <template v-if="item.type === 3">
                                <!-- <feather-icon icon="CloudIcon" size="13" class="text-primary" /> -->
                                <span class="font-small-3" style="padding-left: 5px;">Баннер</span>
                            </template>
                            <template v-if="item.type === 1">
                                <feather-icon icon="CloudIcon" size="13" class="text-primary" />
                                <span class="font-small-3" style="padding-left: 5px;">Облако</span>
                            </template>
                            <template v-if="item.type === 2">
                                <feather-icon icon="CpuIcon" size="13" class="text-danger" />
                                <span class="font-small-3" style="padding-left: 5px;">Майнер</span>
                            </template>
                        </b-td>
                        <b-td class="text-black text-nowrap">
                            <template v-if="item.period">
                                {{ item.period }} мес.
                            </template>
                            <span v-else>-</span>
                        </b-td>
                        <b-td class="text-black text-nowrap">
                            <template>
                                {{ item.until }} ч.
                            </template>
                        </b-td>
                        <b-td class="text-black text-nowrap">
                            <template >
                                <div class="d-flex justify-content-between" style="align-items: center;"> 

                                <!-- <div style="width: 10px; height: 10px; border-radius: 50px;" :style="generate_color(generate_url(item.special.url))"></div> -->
                                    
                                <b-link :href="generate_url(item.special.url)" class="text-ellipsis ">{{start_and_end(generate_url(item.special.url))}}</b-link>
                                    <div v-ripple @click="copyUrl($event, item.id, item.special.url)" style="margin: auto 12px; margin-right: 0;">
                                        <feather-icon icon="CopyIcon" size="20" class="text-muted cursor-pointer" />
                                    </div>
                                </div>
                            </template>
                        </b-td>
                        <b-td class="text-center">
                            <b-dropdown
                                size="sm"
                                right
                                no-caret   
                                variant="transparent"
                            >
                                <template #button-content>
                                    <feather-icon icon="MoreVerticalIcon" class="text-muted" size="22" />
                                </template>
                                <b-dropdown-item v-if="item.type == 3" :to="{ name: 'admin-contracts-offers-add', params: { special_offer: item.special_offer, until: item.until } }">
                                    <feather-icon icon="AddIcon" class="text-primary" size="13" />
                                    <span class="text-primary"> Добавить предложение</span>
                                </b-dropdown-item>
                                <b-dropdown-item :to="item.type == 3 ? { name: 'admin-contracts-offers-edit-banner', params: { offer: item } } : { name: 'admin-contracts-offers-edit', params: { offer: item } }">
                                    <feather-icon icon="EditIcon" class="text-primary" size="13" />
                                    <span class="text-primary"> Редактировать</span>
                                </b-dropdown-item>
                                <b-dropdown-item @click="preferDeleteAction(item, index )">
                                   <feather-icon icon="TrashIcon" class="text-danger" size="13" />
                                   <span class="text-danger"> Удалить</span>
                                </b-dropdown-item>
                            </b-dropdown>
                        </b-td>
                    </b-tr>
                      <div ref="container" class="wordwrap">
                           <span class="text-capitalize font-small-2" style="opacity: 0.55;">
                               
                            </span>
                        </div>
                </b-tbody>
            </b-table-simple>
        </b-card>

        <b-card no-body v-if="individual_specials.length > 0">
            <b-card-header>
                <b-card-title>Индивидуальные спецпредложения</b-card-title>
            </b-card-header>
            
            <b-skeleton-table v-if='is_loading' animation="fade" :columns='9' :rows='5'>
            
            </b-skeleton-table>
            <b-table-simple v-else >
                <b-thead>
                    <b-tr class="font-small-2 text-nowrap text-muted">
                        <b-td>#</b-td>
                        <b-td>Название</b-td>
                        <b-td>Тип</b-td>
                        <b-td>Период</b-td>
                        <b-td>Время жизни</b-td>
                        <b-td>Ссылка</b-td>
                        <b-td></b-td>
                    </b-tr>
                </b-thead>
                <b-tbody>
                    <b-tr v-for="(item, index) in individual_specials" :key="index">
                        <b-td class="text-black text-nowrap"> {{ item.id }} </b-td>
                        <b-td class="text-black text-nowrap font-weight-bolder">
                            <template v-if="item.miner">
                                {{ item.miner.name }}
                            </template>
                            <template v-else >
                                {{ item.title }}
                            </template>
                        </b-td>
                        <b-td class="text-black text-nowrap">
                            <template v-if="item.type === 1">
                                <feather-icon icon="CloudIcon" size="13" class="text-primary" />
                                <span class="font-small-3" style="padding-left: 5px;">Облако</span>
                            </template>
                            <template v-if="item.type === 2">
                                <feather-icon icon="CpuIcon" size="13" class="text-danger" />
                                <span class="font-small-3" style="padding-left: 5px;">Майнер</span>
                            </template>
                        </b-td>
                        <b-td class="text-black text-nowrap">
                            <template v-if="item.period">
                                {{ item.period }} мес.
                            </template>
                            <span v-else>-</span>
                        </b-td>
                        <b-td class="text-black text-nowrap">
                            <template>
                                {{ item.until }} ч.
                            </template>
                        </b-td>
                        <b-td class="text-black text-nowrap">
                            <template >
                                <div class="d-flex justify-content-between" style="align-items: center;"> 
                                
                                <!-- <div style="width: 10px; height: 10px; border-radius: 50px;" :style="generate_color(generate_url(item.special.url))"></div> -->

                                <b-link :href="generate_url(item.special.url)" class="text-ellipsis ">{{start_and_end(generate_url(item.special.url))}}</b-link>
                                    <div v-ripple @click="copyUrl($event, item.id, item.special.url)" style="margin: auto 12px; margin-right: 0;">
                                        <feather-icon icon="CopyIcon" size="20" class="text-muted cursor-pointer" />
                                    </div>
                                </div>
                            </template>
                        </b-td>
                        <b-td class="text-center">
                            <b-dropdown
                                size="sm"
                                right
                                no-caret   
                                variant="transparent"
                            >
                                <template #button-content>
                                    <feather-icon icon="MoreVerticalIcon" class="text-muted" size="22" />
                                </template>
                                <b-dropdown-item :to="{ name: 'admin-contracts-offers-add', params: { special_offer: item.special_offer, until: item.until } }">
                                    <feather-icon icon="AddIcon" class="text-primary" size="13" />
                                    <span class="text-primary"> Добавить предложение</span>
                                </b-dropdown-item>
                                <b-dropdown-item :to="{ name: 'admin-contracts-offers-edit', params: { offer: item } }">
                                    <feather-icon icon="EditIcon" class="text-primary" size="13" />
                                    <span class="text-primary"> Редактировать</span>
                                </b-dropdown-item>
                                <b-dropdown-item @click="preferDeleteAction(item, index )">
                                   <feather-icon icon="TrashIcon" class="text-danger" size="13" />
                                   <span class="text-danger"> Удалить</span>
                                </b-dropdown-item>
                            </b-dropdown>
                        </b-td>
                    </b-tr>
                    <div ref="container" class="wordwrap">
                        <span class="text-capitalize font-small-2" style="opacity: 0.55;"></span>
                    </div>
                </b-tbody>
            </b-table-simple>
        </b-card>
    </div>
    
</template>

<script>

    import Utils from "@/modules/utils/"
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'


    export default {

        data() {
            return {
                Utils,
                is_loading: true,
                items: [],
                specials: [],
                individual_specials: [],
                list_colors: []
            }
        },
        methods: {
            
            preferDeleteAction( offer, index ) {
                
                this.$swal({
                    title: `Подтвердите действие`,
                    text: `Вы действительно хотите удалить это предложение?`,
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Удалить',
                    cancelButtonText: 'Отмена',
                    customClass: {
                      confirmButton: 'btn btn-danger',
                      cancelButton: 'btn btn-outline-secondary ml-1',
                    },
                    buttonsStyling: false,
                  }).then(result => {
                    if (result.value) {
                        this.$request.post("contracts/deleteOffer", {
                            id: offer.id
                        }).then( rsp => {
                            this.items.splice( index, 1 );
                            this.$swal({
                                icon: 'success',
                                text: 'Предложение успешно удалено',
                                customClass: {
                                  confirmButton: 'btn btn-success',
                                },
                            });
                        });
                    }
                  });
                
            },
            
            get() {
                this.$request.get("contracts/getOffers").then(rsp => {
                    this.items = rsp.items;
                    this.is_loading = false;
                });

                this.$request.get("contracts/getSpecialOffers").then(rsp => {
                    this.specials = rsp.web;
                    this.individual_specials = rsp.individual;
                    this.is_loading = false;
                });

            },

            start_and_end(str, start = 10, end = 10) {
                if (str.length > 20) {
                  return str.substr(0, start) + '...' + str.substr(str.length - end , str.length);
                }
                return str;
            },

            copyUrl( args, id, url) {
                
                let container = this.$refs.container;
                url =  this.generate_url(url)
                this.$copyText( url, container );
                
                this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Скопировано.',
                      text: 'Ссылка успешно скопирована в буфер обмена.',
                      icon: 'CheckCircleIcon',
                      variant: 'success',
                    }
                });  
            },
            generate_url(url) {

                if (url !== null) {
                    return process.env.VUE_APP_BASE_URL + '?offer=' + url
                } 
                
                return ''

            },
            generate_color(url) {
                let ind_color = {
                    color: null,
                    url: null
                };

                ind_color = this.list_colors.find(item => item.url == url );

                if (!ind_color) {
                    ind_color = {
                        color: null,
                        url: null
                    };

                    ind_color.color = this.getRandomColor();
                    this.list_colors.push({
                        url: url,
                        color: ind_color.color
                    })
                }

                return {
                    background: ind_color.color
                }

            },
            getRandomColor() {
                var letters = '0123456789ABCDEF';
                var color = '#';
                for (var i = 0; i < 6; i++) {
                    color += letters[Math.floor(Math.random() * 16)];
                }
                return color;
            }
        },
        computed: {
           
           
        },
        components: {

        },
        watch: {

        },
        mounted() {
            this.get();
        }

    }

</script>